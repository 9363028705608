<template>
  <template v-if="load">
    <NavBar />
    <router-view></router-view>
    <FooterVue />

    <div v-if="isPopupVisible" class="popup-overlay">
      <div class="popup-content">
        <p>
          You are about to leave our website and visit:
          <strong>{{ externalLink }}</strong>
        </p>
        <div class="popup-buttons">
          <button @click="continueToLink">Continue</button>
          <button @click="closePopup">Back</button>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div id="preloader">
      <div class="jumper">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </template>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterVue from './components/Footer.vue';
import { mapActions, mapGetters} from 'vuex'

export default {
  components: { NavBar, FooterVue },
  data() {
    return {
      load: false,
    }
  },

  computed: {
    ...mapGetters(['isPopupVisible', 'externalLink']),
  },
  methods: {
    ...mapActions(['loadXMLFiles','hidePopup']),
    
    continueToLink() {
      window.open(this.externalLink, '_blank');
      this.hidePopup();
    },
    closePopup() {
      this.hidePopup();
    },
  },
  mounted() {
    this.loadXMLFiles();
    setTimeout(() => {
      this.load = true;
    }, 1000);

  },

}
</script>


<style>
/* Styles for popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-overlay strong{
  word-wrap: break-word;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.popup-buttons {
  margin-top: 20px;
}

.popup-buttons button {
  outline: none;
  border: none;
  margin: 0 10px;
  color: white;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 15px;
  cursor: pointer;
  background-color: rgb(53, 192, 237);
}
</style>