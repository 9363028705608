import { createStore } from 'vuex'
import router from '@/router';


export default createStore({
  state: {
    isPopupVisible: false,
    externalLink: '',
    msgstatus: 0,
    content: [] // To store the XML data retrieved from the file
  },
  mutations: {
    SET_XML_DATA(state, data) {
      state.content = data;
    },
    clearContent(state, data) {
      state.content = [];
    },

    showPopup(state, link) {
      state.isPopupVisible = true;
      state.externalLink = link;
    },
    closePopup(state) {
      state.isPopupVisible = false;
      state.externalLink = '';
    },
  },

  getters: {
    isPopupVisible: (state) => state.isPopupVisible,
    externalLink: (state) => state.externalLink,
  },

  actions: {

    triggerPopup({ commit }, link) {
      commit('showPopup', link);
    },
    hidePopup({ commit }) {
      commit('closePopup');
    },

    // sendMessage(context, params) {
    //   // Ensure all fields are filled
    //   for (const key in params) {
    //     if (params[key] === "") {
    //       return alert('Please fill all the fields');
    //     }
    //   }
    
    //   context.state.msgstatus = 1; // Status: Sending
    //   const url = `https://www.echosilence.info/scripts/contact.php?name=${encodeURIComponent(params.name)}&email=${encodeURIComponent(params.email)}&subject=${encodeURIComponent(params.subject)}&text=${encodeURIComponent(params.text)}`;
    
    //   fetch(url)
    //     .then(response => {
    //       if (!response.ok) {
    //         context.state.msgstatus = 3; // Status: Error
    //         throw new Error('Failed to fetch');
    //       }
    //       context.state.msgstatus = 2; // Status: Success
    //       return response.json(); // Parse response
    //     })
    //     .catch(error => {
    //       console.error('Error:', error);
    //       context.state.msgstatus = 3; // Status: Error
    //     });
    // },



    
    
    async loadXMLFiles({ commit }) {
      var filepaths = ['home', 'about', 'servicesAndProducts', 'apps', 'blog', 'contact'];
      var promises = [];
      var xmlString = "<root>";
      for (const key in filepaths) {
        const file = filepaths[key];
        var fetchPromise = fetch(`website/content/${file}.xml`)
          .then(response => response.text())
          .then(data => {
            xmlString += data;
          })
          .catch(error => {
            alert('Error fetching XML file');
          });
        promises.push(fetchPromise);
      }
      Promise.all(promises)
        .then(() => {
          xmlString += "</root>"
          var parser = new DOMParser();
          var xmlDoc = parser.parseFromString(xmlString, "text/xml");
          var result = xmlToObject(xmlDoc);
          commit('SET_XML_DATA', result.root)
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
  },
  modules: {
  }
})

function xmlToObject(xml) {
  var obj = {};
  if (xml.nodeType == 1) {
    if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType == 3) {
    obj = xml.nodeValue.trim();

  }
  if (xml.hasChildNodes()) {

    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);
      var nodeName = item.nodeName;
      if (typeof obj[nodeName] == "undefined") {
        obj[nodeName] = xmlToObject(item);
        if (typeof obj[nodeName]['#text'] == "string") {
          obj[nodeName] = obj[nodeName]['#text'].replaceAll("--", "<br>&nbsp;•&nbsp;")
        } else if (typeof obj[nodeName]['#text'] == "object") {
          delete obj[nodeName]['#text'];
        }
      } else {
        if (typeof obj[nodeName].push == "undefined") {
          var old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToObject(item));
      }
    }
  }
  return obj;
}
