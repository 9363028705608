<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-3 footer-item">
          <h4>Echo Silence</h4>
          <p>Echo Silence's mission is to create a quieter and Healthier Future with Advanced Acoustic Technology!</p>
          <!-- <ul class="menu-list">
          <li>
            <a target="_blank" href="/privacy-policy" style="color:rgb(53, 192, 237)">Privacy Policy</a>
            </li>
            </ul> -->
        </div>
        <div class="col-md-3 footer-item">
          <h4>Useful Links</h4>
          <ul class="menu-list">

            <li> <i style="color: rgb(53, 192, 237); font-size: 15px; width: 20px;" class="fa fa-linkedin"   ></i>
              <a  @click.prevent="openPopup(content.linkedin)" href="#">Linked In</a>
            </li>
            <li> <i style="color: rgb(53, 192, 237); font-size: 15px; width: 20px;" class="fa fa-envelope">
              </i> <a target="_blank" :href="'mailto:' + content.email">Contact email</a></li>
            <li> <i style="color: rgb(53, 192, 237); font-size: 15px; width: 20px;" class="fa fa-phone"></i>
              <a target="_blank" :href="'tel:' + content.phone">Mobile number</a>
            </li>
            <li> <i style="color: rgb(53, 192, 237); font-size: 15px; width: 20px;" class="fa fa-map-marker"></i>
              <a @click.prevent="openPopup(gmaps)" href="#">Address</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 footer-item">
          <h4>Website Pages</h4>
          <ul class="menu-list">
            <!-- <li><router-link @click="scrollTop()" to="home">Home</router-link></li> -->
            <li><router-link @click="scrollTop()" to="services-and-products">Services & Products</router-link></li>
            <li><router-link @click="scrollTop()" to="applications">Applications</router-link></li>
            <li><router-link @click="scrollTop()" to="about">About</router-link></li>
            <li><router-link @click="scrollTop()" to="contact-us">Contact Us</router-link></li>

            <li> <a target="_blank" href="/privacy-policy" >Privacy Policy</a></li>

          </ul>
        </div>
        <div class="col-md-3 footer-item">
          <h4>Company info</h4>
          <p>
            <span style="color:rgb(53, 192, 237)">Address: </span> {{ content.location }}
          </p>
          <p>
            <span style="color:rgb(53, 192, 237)">Tax Number: </span> {{ content.taxnumber }}
          </p>
          <p>
            <span style="color:rgb(53, 192, 237)">Mobile Number: </span> {{ content.phone }}
          </p>
        </div>


        <!-- <div class="col-md-3 footer-item last-item">
          <h4>Legal</h4>
          <ul class="menu-list">
          <li>
            <a target="_blank" href="/privacy-policy">Privacy Policy</a>
            </li>
            </ul>

        </div> -->
      </div>
    </div>
  </footer>

  <div class="sub-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>Copyright &copy; 2025 EchoSilence
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  props: [],
  data() {
    return {

    }
  },
  computed: {
    gmaps() {
      return 'https://www.google.com/maps?q=' + this.content.location.replaceAll(" ", "+")
    },
    content() {
      return this.$store.state.content.contact
    },
    msgstatus() {
      return this.$store.state.content.footermsgstatus
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    ...mapActions(['triggerPopup']),
    openPopup(link) {
      this.triggerPopup(link);
    },
  },
  mounted() { },
  unmounted() { }
}
</script>
<style></style>