<template>

    <div class="page-heading header-text">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>About Us</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="services mb-4">
      <div class="container">
        <div class="row">
          <div class="col-md-6 my-2" v-for="(item, index) in content.infos.info" v-bind:key="index">
            <div class="service-item" style="height: calc(100% - 80px);">
              <div class="d-flex justify-content-center">
                <div class="img-bubble" :style="`background-image: url('/website/images/${item.image}')`"></div>
              </div>  <div class="down-content text-center" style="height: 100%;">
                <h4>{{ item.title }}</h4>
                <p>{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="team">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-heading">
              <h2>Our team <em>members</em></h2>
              <span>As an international team of innovators, we strive to create a better future together</span>
            </div>
          </div>

          <div class="col-md-4 my-2" v-for="(item, index) in content.members.member" v-bind:key="index">
            <div class="team-item text-center bg-white">
              <img :src="`website/images/${item.image}`" alt="">
              <div class="down-content">
                <h4>{{ item.name }}</h4>
                <span>{{ item.title }}</span>
                <a class="btn btn-light btn-sm" @click.prevent="openPopup(item.linkedin)" href="#">Linked <i style="transform: translateY(-0.7px); color: rgb(53, 192, 237);" class="fa fa-linkedin mb-1"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

import { mapActions } from 'vuex'
export default {
  computed: {
    content(){
      return this.$store.state.content.about
    }
  },
  methods: {

    ...mapActions(['triggerPopup']),
    openPopup(link) {
      this.triggerPopup(link);
    },
  },
  mounted() {
  },

}
</script>
<style></style>