<template>
    <template v-if="msgstatus == 1">
        <div id="preloader">
            <div class="jumper">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="page-heading header-text">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="contact-information mb-4">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="contact-item">
                            <i class="fa fa-phone"></i>
                            <h4>Phone</h4>
                            <p>Get in touch instantly! Dial our mobile number.</p>
                            <a :href="'tel:' + content.phone">{{ content.phone }}</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="contact-item">
                            <i class="fa fa-envelope"></i>
                            <h4>Email</h4>
                            <p>For any questions or concerns, feel free to email us at</p>
                            <a :href="'mailto:' + content.email">{{ content.email }}</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="contact-item">
                            <i class="fa fa-map-marker"></i>
                            <h4>Location</h4>
                            <p>{{ content.location }}</p>
                            <a @click.prevent="openPopup(gmaps)" href="#">View on Google Maps</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="callback-form contact-us">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="msgstatus == 0" class="section-heading">
                            <h2>Send us a <em>message</em></h2>
                            <span>Prefer another way to get in touch? Our contact form is also available for your
                                convenience</span>
                        </div>
                        <div v-else-if="msgstatus == 2">
                            <h2>Your Message Has been sent!</h2>
                        </div>
                        <div v-else>
                            <h2>There was a problem sending your message</h2>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div v-if="msgstatus == 0" class="contact-form">
                            <form id="contact" @submit.prevent="sendMessage" method="get">
                                <div class="row">
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <fieldset>
                                            <input v-model="params.name" type="text" class="form-control" id="name"
                                                placeholder="Full Name">
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <fieldset>
                                            <input v-model="params.email" type="text" class="form-control" id="email"
                                                pattern="[^ @]*@[^ @]*" placeholder="E-Mail Address">
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <fieldset>
                                            <input v-model="params.subject" type="text" class="form-control" id="subject"
                                                placeholder="Subject">
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-12">
                                        <fieldset>
                                            <textarea v-model="params.text" rows="6" class="form-control" id="text"
                                                placeholder="Your Message"></textarea>
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-12">
                                        <fieldset>
                                            <button type="submit" class="filled-button">Send Message</button>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </template>
</template>
<script>

import { mapActions } from 'vuex'

export default {
    data() {
        return {
            loading: false,
            params: {
                name: "",
                email: "",
                subject: "",
                text: ""
            }
        }
    },
    computed: {

        gmaps() {
            return 'https://www.google.com/maps?q=' + this.content.location.replaceAll(" ", "+")
        },
        msgstatus() {
            return this.$store.state.msgstatus
        },
        content() {
            return this.$store.state.content.contact
        }
    },


    methods: {

        ...mapActions(['triggerPopup']),
        openPopup(link) {
            this.triggerPopup(link);
        },

        // sendMessage() {
        //     this.$store.dispatch('sendMessage', this.params)
        // }
    },
    mounted() {
    }
}
</script>
<style></style>